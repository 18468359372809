.hidden {
  display: none;
}

div.addItemIcon {
  justify-self: end;
}

div.addThisLabel {
  font-size: 60%;
  font-weight: normal;
  padding-left: 4px;
}

div.addNewShell {
  border: 0px solid #c2c2c2;
  padding: 5px 0 5px 0;
  margin: 10px 0 21px 0;
}

li.editItemFormLi {
  margin: 0px 0 8px 0;
}

div.editItemDiv {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 72px;
  grid-column-gap: 5px;
  margin-top: 10px;
}

span.iconBorder {
  border-width: 1px;
  border-style: solid;
  border-color: #99cccc;
  padding: 8px 4px 0px 4px;
}

span.iconNoBorder {
  border-width: 1px;
  border-style: solid;
  border-color: #ffffff;
  padding: 8px 4px 0px 4px;
}

.editItemInputArea {
  display: grid;
  grid-template-columns: 4fr 4fr;
  grid-column-gap: 5px;
}

span.iconEdit, span.iconCheckmark, span.iconDelete {
  cursor: pointer;
}

.editButtonArea {
  align-self: start;
  display: flex;
  padding-top: 12px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
 }

/* elements in standard list one item */


li.oneItemLine {
  margin: 0px 0 4px 0;
  padding: 0;
}

div.itemOneRow {
  border: 1px solid #eebbee;
  border-width: 0 0 0px 0;
  display: grid;
}
div.dragHandle, div.notDragHandle {
  grid-area: p1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 2px 0px 2px;
  touch-action: none;
  cursor: move;
}
div.notDragHandle {
  cursor: default;
}
div.dragHandleIcon2 {
  touch-action: none; 
  padding-top: 4px;
  background-color: #bbbbbb;
}
div.noDragHandleIcon2 {
  width: 22px;
  height: 30px;
  background-color: #bbbbbb;
}
div.dragHandleIcon {
  touch-action: none; 
  padding-top: 0px;
  background-color: #ffffff;
}
div.noDragHandleIcon {
  width: 22px;
  height: 30px;
  background-color: #ffffff;
}

div.itemPart23 {
  border: 0px solid #00bbbb;
  grid-area: p23;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0px;
  padding: 8px 6px 0px 6px;
}
div.itemPart2 {
  border: 0px solid #00bbbb;
  padding: 0;
  margin: 0;
}
div.itemPart3 {
  border: 0px solid #00bbbb;
  padding: 0;
  margin: 0;
  font-size: 75%;
}
div.itemPart4 {
  grid-area: p4;
  padding:  4px 0px 0px 0px;
  align-self: center;
}
div.itemPart5 {
  grid-area: p5;
  padding: 4px 0px 0px 0px;
  align-self: center;
}

div.itemPart23 hr {
  background-color: #444444;
  border: none;
  height: 1px;
}

@media (min-width: 450px) { /* desktop */
  div.itemOneRow {
    grid-template-columns: 28px 1fr 36px 36px;
    grid-template-areas: 
      "p1  p23  p4  p5"
      "p1  p23  p4  p5";
    max-width: 600px;
  }
  div.editItemDiv, div.addNewShell {
    max-width: 600px;
  }
}

@media (max-width: 449px) and (min-width: 320px) { /* standard device */
  li.oneItemLine {
    margin: 0px 0 6px 0;
  }
  div.itemOneRow {
    border-width: 0 0 0px 0;
    grid-template-columns: 28px 1fr 34px 36px;
    grid-template-areas: 
      "p1  p23  p4  p5"
  }
  div.itemPart23 {
    padding: 2px 6px 0px 6px;
  }
  div.itemPart2 {
    padding: 4px 0 0 0;
    margin: 0;
  }
  div.itemPart3 {
    padding: 0 0 4px 0;
    margin: 0;
  }
  div.itemPart4 {
    padding: 4px 0px 0px 0px;
  }  
  div.itemPart5 {
    padding: 4px 2px 0px 0px;
  }
  .editItemInputArea {
    grid-template-columns: 1fr;
    grid-row-gap: 0px;
  }
  div.addItemIcon {
    padding-bottom: 15px;
    justify-self: center;
  }
}
 

@media (max-width: 319px) { /* tiny device */
  div.itemOneRow {
    border-width: 0 0 1px 0;
    grid-template-columns: 28px 1fr 1fr 1fr 1fr;
    grid-template-areas: 
      "p1  p23  p23  p23  p23"
      "p1  p4  p5 . . ";
  } 
  div.itemPart2 {
    font-size: 85%;
  }
  div.itemPart4 {
    padding: 4px 0px 5px 12px;
    justify-self: start;
  }  
  div.itemPart5 {
    padding: 4px 2px 5px 0px;
    justify-self: start;
  }
  div.editItemDiv {
    grid-template-columns: 1fr;
    grid-row-gap: 0px;
  }
  .editItemInputArea {
    grid-template-columns: 1fr;
    grid-row-gap: 0px;
  }
}
 

