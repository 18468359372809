.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
 }

/* elements in standard list one item */

li.oneListLine {
  margin: 0px 0 10px 0;
}

div.listOneRow {
  border: 1px solid #eebbee;
  border-width: 0px 0px 0px 0px;
  display: grid;
}
div.listOneRowUncat {
  padding: 0 10px 0 10px;
}
div.listPart2 {
  grid-area: p2;
  padding: 4px 0px 8px 6px;
}
div.listPart3 {
  grid-area: p3;
  padding: 8px 6px 8px 10px;
  font-size: 75%;
  justify-self: end;
}


div.addItemIconContainer {
  max-width: 600px;
  display: grid;
  padding-top: 17px;
  padding-bottom: 25px;
}

@media (min-width: 451px) { /* desktop */
  div.listOneRow {
    grid-template-columns: 28px 8fr 3fr;
    grid-template-areas: 
      "p1  p2  p3";
    max-width: 600px;
  }
}

@media (max-width: 450px) and (min-width: 320px) { /* regular device */
  div.listOneRow {
    grid-template-columns: 28px 1fr 75px;
    grid-template-areas: 
      "p1  p2  p3";
  } 
  div.listPart2 {
    padding: 4px 4px 6px 6px;
  }
  div.listPart3 {
    padding: 6px 4px 4px 5px;
  }

  div.addItemIconContainer {
    padding-top: 15px;
    padding-bottom: 10px;
  }
}
 

@media (max-width: 319px) { /* smaller device */
  div.listOneRow {
    grid-template-columns: 28px 1fr;
    grid-template-areas: 
      "p1  p2"
      "p1  p3";
  } 
  div.listPart2 {
    padding: 6px 4px 0px 6px;
  }
  div.listPart3 {
    padding: 2px 4px 8px 6px;
    justify-self: start;
  }  
}
 

