div.iconButton, div.iconButtonNarrow, div.iconButtonWide, div.iconButtonXWide {
  border: 0px solid #bbbbbb;
  background-color: #ffffff;
  padding: 2px 2px 0px 0px;
  width: 66px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: center;
  justify-items: center;
  cursor: pointer;
  background-color: transparent;
}

div.iconButtonDisabled {
  cursor: not-allowed;
}

div.theIcon {
  border: 0px solid #00bbbb;
}

div.iconButtonNarrow {
  width: 36px;
}

div.iconButtonWide, div.iconButtonXWide {
  width: 90px;
}

div.iconCaption, div.iconLinkCaption {
  border: 0px solid #00bbbb;
  padding: 4px 6px 0px 6px;
  font-size: 10px;
  line-height: 150%;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

div.iconCaptionDisabled {
  color: #bbbbbb;
}

div.iconButtonWide div.iconCaption, div.iconButtonXWide div.iconCaption {
  display: flex;
  flex-wrap: no-wrap;
  padding-bottom: 0;
  margin-bottom: 0;
}

.iconButton a:hover, .iconButton a:visited, .iconButton a:link, .iconButton a:active {
  text-decoration: none;
  color: black;
}

@media (min-width: 450px) and (max-width: 599px) { /* narrow desktop */
  div.iconCaption {
    font-size: 9px;
  }
  div.iconButton {
    width: 60px;
  }
  div.iconButtonWide {
    width: 85px;
  }
}

@media (min-width: 360px) and (max-width: 449px) {
  div.iconCaption {
    font-size: 9px;
  }
  div.iconButtonWide, div.iconButton {
    width: 60px;
    margin-bottom: 10px;
  }
}


@media (max-width: 359px) {
  div.iconCaption {
    font-size: 9px;
  }
  div.iconButtonWide, div.iconButton {
    width: 60px;
    margin-bottom: 10px;
  }
  div.iconButtonXWide {
    margin-bottom: 10px;
  }
}

