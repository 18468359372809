
.chooseFlatnessForm, .chooseNicknameForm, .chooseRememberForm {
  margin: 20px 0 15px 0;
  border: 1px #aaaaaa solid;
  padding: 14px 8px 14px 22px;
}

.loginForm {
  padding: 0 5px 0 10px;
}

div.formlabel {
  margin: 25px 0 7px 0;
  line-height: 25px;
  padding: 0 8px 0 10px;
}

div.formlabel2 {
  margin: 2px 0 10px 0;
  line-height: 25px;
  padding: 0 8px 0 0px;
}
div.nickFormRow {
  display: flex;
  flex-direction: row;
}
div.nickEditButtonArea {
  display: flex;
  flex-direction: row;
  padding: 12px 0 0 20px;
}
div.iconRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px 0px 0px 0px;
  margin: 15px 0px 3px 0px;
}
div.oneIconInRow {
  padding: 8px;
  border: 1px solid #aaaa88;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: #e3f2fd;
  background-color: #ffd600;  
}

div.loginLine {
  display: grid;
  grid-template-columns: 1fr 54px;
}

.loginLine2 {
  justify-self: start;
  align-self: start;
  padding: 10px 6px 0 0;
}

.loginButtonArea {
  padding-top: 15px;
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: end;
  justify-items: start;
}

form.regForm {
  max-width: 400px;
}

.regbox {
  margin: 0 0 2px 0;
}

.regButton {
  margin: 20px 0 0px 0;
  border: 1px #bbbbbb solid;
  width: 70px;
  padding: 16px 4px 16px 4px;
  background-color: #eeeeee;
}

div.regFormMessage {
  border: 0px #bbbbbb solid;
  font-size: 12px;
  font-weight: bold;
  padding: 11px 0 12px 12px;
  background-color: #ffffff;
}

div.testArea {
  border: 1px solid #44ccaa;
  padding: 20px;
}

div.helpHeader {
  font-size: 20px;
  line-height: 175%;
  font-weight: bold; 
  padding: 10px 10px 10px 12px;
}

div.helpPara {
  font-size: 16px;
  line-height: 175%;
  padding: 10px 10px 10px 12px;
  margin-bottom: 15px;
}

div.vnum {
  font-size: 10px;
  font-weight: 500;
  padding: 22px 0 0 12px;
}

div.regLoad {
  padding: 10px 0 0 0;
  width: 100%;
  height: 100%;
}

.qrcodeimg {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 50px;
}

.qrcodeimg img {
  width: 75%;
}

@media (min-width: 450px) { /* desktop */

}

@media (max-width: 449px) { /* device */
  div.regLoad {
    background-color: #33691e;
  }
  form.regForm {
    padding: 0 12px 0 12px;
  }
}
 


