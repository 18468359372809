

body {
  font-family: Roboto, Verdana, Segoe, sans-serif;
  background-color: #ffffff;
  }


.mainContainer {
  padding: 0px 0 14px 0;
  margin: 0px 0 10px 0;
  font-size: 18px;
  text-align: left;
  background-color: #ffffff;
}

.hidden {
  display: none;
}

.headingZone {
  padding: 7px 5px 12px 3px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) 136px;
  max-width: 600px;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #bbbbbb;
  border-width: 3px;
}

.topHeading {
  background-color: #ffecb3;
  background-color: #ffffff;
}

.catHeading {
  background-color: #c8e6c9;
  background-color: #ffffff;
}

.listHeading {
  background-color: #dcedc8;
  background-color: #ffffff;
}

.helpHeading {
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #ffe0b2;
  background-color: #ffffff;
}

.loginHeading {
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #e3f2fd;
  background-color: #ffffff;
}

.headingNameArea {
  border: 0px solid black;
  font-size: 24px;
  line-height: 140%;
  font-weight: bold;
  padding: 5px 0 0 10px;
  overflow-wrap: break-word;
}

.headingIcons {
  border: 0px solid black;
  padding: 2px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-self: end;
  align-self: start;
  align-items: flex-start;
}

.headingIconsSmaller {
  padding-top: 7px;
}

div.moveArea {
  border: 1px solid #99cccc;
  padding: 0px 12px 14px 12px;
  width: 410px;
  margin: 15px 0 16px 0;
  background-color: #f6f6f6;  
}

div.moveHeader {
  padding: 15px 12px 14px 12px;
  font-size: 17px;
  font-weight: bold;
}

form.moveCategoryForm {
  padding: 0 0 15px 32px;
}

div.moveButtonArea {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #aaaaaa;
  display: grid;
  padding: 20px 10px 10px 18px;
  grid-template-columns: 100px 1fr;  
}

div.addArea {
  display: grid;
  grid-template-columns: 1fr 150px;
  border: 1px solid #99cccc;
  padding: 10px 12px 14px 12px;  
  margin: 15px 0 16px 0;
  background-color: #f6f6f6;
}

div.addAreaNarrow {
  display: grid;
  grid-template-columns: 1fr 72px;
  border: 0px solid #99cccc;
  padding: 10px 12px 14px 12px;
  padding: 0;
  margin: 15px 0 16px 0;
  margin: 10px 0 0 0;
  background-color: #ffffff;
}

div.showAddArea {
  padding: 0px 0px 0px 20px;
  font-weight: normal;
  margin: 15px 0 18px 0;
}

button {
  margin: 4px 5px 4px 5px; 
}

.linky3 {
  padding: 5px 5px 5px 0;  
}

.additem {
  border: 0px #440044 solid;
  padding: 5px;  
  margin-bottom: 15px;
}

div.itemsTable {
  border: 0px solid #999999;
  margin: 15px 0 0 0;
}

ul.itemsTableUL {
  padding: 0 10px 0 10px;
  margin: 0;
  list-style: none;
}

.headerAddLabel {
  font-size: 14px;
  padding: 0 0 15px 15px;
}


.editButtonArea {
  align-self: end;
  display: flex;
  justify-content: flex-end;
}

.breadcrumbs {
  border: 0px solid #99cccc;
  padding: 12px 8px 15px 12px;
  text-align: left;
  font-size: 16px;
  line-height: 140%;
}

a:hover, a:visited, a:link, a:active {
  color: #6d4c41;
}

.breadcrumbs a:hover, .breadcrumbs a:visited, .breadcrumbs a:link, .breadcrumbs a:active {
  color: #aa4444;
}

.oneCrumb {
  padding: 0 8px 0 0;
}

div.crumbsandsettings {
  max-width: 600px;
  display: grid;
  grid-template-columns: 1fr 38px;
  gap: 10px;
}

div.crumbsplustwo {
  max-width: 600px;
  display: grid;
  grid-template-columns: 1fr 38px 38px;
  gap: 4px;
}


div.crumbsplusthree {
  max-width: 600px;
  display: grid;
  grid-template-columns: 1fr 38px 38px 38px;
  gap: 4px;
}

div.crumbsplusfour {
  max-width: 600px;
  display: grid;
  grid-template-columns: 1fr 38px 38px 38px 38px;
  gap: 4px;
}

div.settingsicon {
  padding: 5px 5px 0px 0px;
  justify-self: end;
  align-self: center;
}

div.helpicon {
  padding: 7px 5px 0px 0px;
  justify-self: end;
  align-self: center;
}

.cursorPointer {
  cursor: pointer;
}

div.noneYetMsg {
  margin: 30px 0 10px 0;
  padding: 0 0 0 15px;
}

div.moreIcons {
  padding: 16px 0px 11px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: -8px;
}

@media (min-width: 600px) { /* desktop */
  .mainContainer {
    width: 600px;
  }
  div.crumbsandsettings {
    max-width: 600px;
  }
  .breadcrumbs {
    font-size: 15px;
  }
}

@media (min-width: 450px) and (max-width: 599px) { /* narrow desktop */
  .mainContainer {
    width: 97%;
  }
  div.crumbsandsettings {
    width: 100%;
  }
  .breadcrumbs {
    font-size: 15px;
  }
  div.moreIcons {
    padding-right: 0px;
    margin-right: 0px;
  }
  div.moreIcons5 {
    padding-right: 0;
  }
}

@media (max-width: 449px) { /*  device */
  div.moreIcons5 {
    padding-right: 0;
    justify-content: space-between;
  }
  .hiddenOnDevice {
    display: none;
  }
  .mainContainer {
    width: 97%;
    margin-top: 0;
    padding-top: 0;
    padding: 0 7px 0 7px;
  }
  div.crumbsandsettings {
    width: 100%;
  }
  div.itemsTable {
    margin: 10px 0 0 0;
  }
  .headingNameArea {
    font-size: 22px;
  }
  .headingZone {
    max-width: 100%;
    padding: 7px 5px 12px 3px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 128px;
  }
}

@media (max-width: 359px) { /*  smaller device */
  .headingNameArea {
    font-size: 18px;
  }
  div.moreIcons5 {
    padding-right: 0;
    justify-content: right;
  }
}
